import { MantineProvider } from '@mantine/core';
import { useEffect } from 'react';
import { TOKEN_KEY } from '../constants/app.constant';
import { MembershipPlanContextProvider } from '../context/membership-plan-context/membership-plan-context';
import { useAuthentication } from '../hooks/useAuth';
import { userInfoServices } from '../services/admin.service';
import { getAllConfigurationService } from '../services/app-setting.service';
import { useConfigurationStore } from '../zustands/config.zustand';
import { useGlobalLoading } from '../zustands/global-loading.zustand';
import App from './Apps';

const StoreProviderWrapper = () => {
  const { setConfigData } = useConfigurationStore();
  const { setGlobalLoading } = useGlobalLoading();
  const userGlobal = useAuthentication();

  useEffect(() => {
    if (localStorage?.getItem(TOKEN_KEY)) {
      setGlobalLoading(true);
      userInfoServices()
        .then((res) => {
          getAllConfigurationService().then((res) => {
            const config = res?.data;
            setConfigData(config);
          });
          setGlobalLoading(false);
        })
        .finally(() => setGlobalLoading(false));
    }
  }, [userGlobal.token, setGlobalLoading, setConfigData]);
  return (
    <MantineProvider>
      <MembershipPlanContextProvider>
        <App />
      </MembershipPlanContextProvider>
    </MantineProvider>
  );
};
export default StoreProviderWrapper;
