/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { createContext, FC, ReactNode, useContext } from 'react';
import {
  TResponseCurrentMemberShip,
  useCurrentMembershipService,
} from '../../services/membership.service';

interface MembershipPlanContextInterface {
  isHaveMembership: boolean;
  membershipData: TResponseCurrentMemberShip;
}

const MembershipPlanContext = createContext<
  MembershipPlanContextInterface | undefined
>(undefined);

const MembershipPlanContextProvider: FC<{
  children: ReactNode;
}> = (props) => {
  const { data: currentMembershipData } = useCurrentMembershipService({
    page: 1,
    limit: 10,
  });

  return (
    <MembershipPlanContext.Provider
      value={{
        isHaveMembership:
          currentMembershipData?.original_price !== 0 ? true : false,
        membershipData: currentMembershipData,
      }}
    >
      {props?.children}
    </MembershipPlanContext.Provider>
  );
};

const useMembershipPlanContext = () =>
  useContext(MembershipPlanContext) as MembershipPlanContextInterface;

export { MembershipPlanContextProvider, useMembershipPlanContext };
