export enum ROUTE_PATH {
  LOGIN = '/login',
  NOT_EXIST = '/*',
  DASHBOARD = '/dashboard',
  SETTING = '/setting/:key',
  USER_SETTING = '/user-setting',
  CONFIGURATION = '/config',
  HOME = '/home',
  CONTENT = '/content',
  TEST_PAGE = '/test-page',
  CATEGORY = '/category',
  PLAY = '/play',
  LIBRARY = '/library/:key',
  LIBRARY_ONE = '/library',
  CATEGORY_DETAIL = '/category/:id',
  VIDEO_DETAIL = '/video/:id',
  VIDEO = '/video',
  SMART_LIBRARY = '/smart-library',
}
