import { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { uid } from 'uid';
import { ROUTE_PATH } from '../constants/path.route';
import { useAuthentication } from '../hooks/useAuth';
import AsurRaaProMasterLayout from '../layouts/master.layout';
import LoginPage from '../pages/login/login.page';

import { useGetRouteView } from '../router/router.view';

const App = () => {
  const { token, setRoleByToken } = useAuthentication();
  const { layoutRouteView, layoutRouteList } = useGetRouteView();

  useEffect(() => {
    setRoleByToken();
  }, [setRoleByToken]);

  if (token) {
    return (
      <Routes>
        <Route
          element={
            <AsurRaaProMasterLayout>{layoutRouteView}</AsurRaaProMasterLayout>
          }
          path=""
        >
          {[...layoutRouteList]}
        </Route>
      </Routes>
    );
  }

  return (
    <Routes>
      <Route element={<LoginPage />} path={ROUTE_PATH.LOGIN} key={uid()} />
      <Route
        path={ROUTE_PATH.NOT_EXIST}
        element={<Navigate to={ROUTE_PATH.LOGIN} replace={true} />}
        key="REDIRECT"
      />
    </Routes>
  );
};
export default App;
