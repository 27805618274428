import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enTranslateData from './en.json';
import kmTranslateData from './km.json';

export interface LangKeyInterface {
  name: string;
  key: string;
  message: string;
}

export const setLangToLocalStorage = (lang: string) =>
  window.localStorage.setItem('i18nextLng', lang);
const getLangFromLocalStorage = window.localStorage.getItem('i18nextLng');
export const isLangFromLocalStorageAndDefault = () => {
  return getLangFromLocalStorage === null ? 'en' : getLangFromLocalStorage;
};

export const langKeyArrayData: Array<LangKeyInterface> = [
  {
    key: 'en',
    name: 'English',
    message: `🇬🇧 ${' '}English`,
  },
  {
    key: 'km',
    name: 'Khmer',
    message: `🇰🇭 ${' '}Khmer`,
  },
];

export const langKeyArray = (langConfig: string) => {
  const lang = 'km, en';
  return langKeyArrayData.filter((data, index) => lang.includes(data.key));
};

export const appLanguage = ['en', 'km'] as const;
export type AppLanguageType = typeof appLanguage[number];

i18n.use(initReactI18next).init({
  resources: {
    km: {
      translation: {
        ...kmTranslateData,
      },
    },
    en: {
      translation: {
        ...enTranslateData,
      },
    },
  },
  lng: getLangFromLocalStorage ?? 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
  react: {
    useSuspense: false,
  },
  compatibilityJSON: 'v3',
});

export default i18n;
