import { URI_PATH } from './uri-path.constant';

export const API_BASE_URL = process.env['NX_API_HOST'];

export const TOKEN_KEY = 'token';
export const REFRESH_TOKEN_KEY = 'refresh_token';
export const USER_ID_KEY = 'user_id';
export const swChangeDetectName = '__reload_for_sw' as const;
export const isProduction = process.env['NX_APP_MODE'] === 'production';

export const returnImageRoute = `${process.env['NX_API_HOST']}/upload/image/`;

export const getImageFullPath = (imageId: string) => {
  return API_BASE_URL + URI_PATH.GET_IMAGE + imageId;
};
export const fallBackImage =
  'https://raw.githubusercontent.com/asurraa/assets/master/fallback/fallback-white.png';

export const fallbackAvatarImage =
  'https://raw.githubusercontent.com/asurraa/assets/master/fallback/default_avatar.png';
