export enum URI_PATH {
  // * UPLOAD
  'UPLOAD' = '/upload/image',
  'GET_IMAGE' = '/upload/image/',
  'GET_ALL_IMAGE' = '/upload',
  'GET_VIDEO' = '/upload/video/',

  //* membership

  GET_CURRENT_MEMBERSHIP = '/users/current-membership',

  // * DashboardGraph
  'GET_DASHBOARD_CHART' = '/dashboard/chart',
  'GET_DASHBOARD_CARD' = '/dashboard/card-data',

  //*Translation
  'GET_TRANSLATION_WITHOUT_SLASH' = '/translation/original-data',
  'GET_TRANSLATION_BY_KEY_WITHOUT_SLASH' = '/translation/messages/',
  'GET_TRANSLATION' = '/translation/original-data',
  'CREATE_TRANSLATION' = '/translation/create',
  'UPDATE_TRANSLATION' = '/translation/update',
  'DELETE_TRANSLATION' = '/translation/delete',

  //* User
  'CREATE_USER' = '/users/create',
  'GET_ALL_USER' = '/users/all',
  'GET_ONE_USER' = '/users/one',
  'DELETE_ONE_USER' = '/users/delete',
  'UPDATE_ONE_USER' = '/users/update',
  'USER_CHANGE_STATUS' = '/users/change-status',
  'USER_CHANGE_PASSWORD' = '/users/change-password',
  'USER_LOGIN' = '/users/sign-in',
  'USER_CHANGE_PASSWORD_BY_ADMIN' = '/users/change-user-password',

  //* Admin Role
  'GET_ALL_ADMIN_ROLE' = '/admin-role/all',
  'CREATE_ADMIN_ROLE' = '/admin-role/create',
  'UPDATE_ADMIN_ROLE' = '/admin-role/update',
  'DELETE_ADMIN_ROLE' = '/admin-role/delete',
  'ADMIN_ROLE_CHANGE_STATUS' = '/admin-role/change-status',

  //* Admin
  'GET_ALL_ADMIN' = '/administrator/all',
  'CREATE_ADMIN' = '/administrator/create',
  'UPDATE_ADMIN' = '/administrator/update',
  'UPDATE_ADMIN_PASSWORD' = '/administrator/change-password',
  'UPDATE_ADMIN_OWN_INFO' = '/administrator/update-own-information',
  'ADMIN_CHANGE_STATUS' = '/administrator/change-status',
  'ADMIN_LOGIN' = '/administrator/login',

  'GET_USER_INFO' = '/users/own-info',

  //* Playlist Category
  'GET_ALL_PLAYLIST_CATEGORY' = '/playlist-category/all',
  'CREATE_PLAYLIST_CATEGORY' = '/playlist-category/create',
  'UPDATE_PLAYLIST_CATEGORY' = '/playlist-category/update',
  'DELETE_PLAYLIST_CATEGORY' = '/playlist-category/delete',
  'GET_ONE_PLAYLIST_CATEGORY' = '/playlist-category/one',

  //* Playlist
  'GET_ALL_PLAYLIST' = '/playlist/all',
  'CREATE_PLAYLIST' = '/playlist/create',
  'UPDATE_PLAYLIST' = '/playlist/update',
  'DELETE_PLAYLIST' = '/playlist/delete',
  'GET_SHORT_PLAYLIST' = '/playlist/sort-contents',
  'APPROVE_CHANGE_STATUS' = '/playlist/approve-status',

  //* Membership
  'GET_ALL_MEMBERSHIP' = '/membership/all',
  'GET_ONE_MEMBERSHIP' = '/membership/one',
  'CREATE_MEMBERSHIP' = '/membership/create',
  'DELETE_MEMBERSHIP' = '/membership/delete',
  'UPDATE_MEMBERSHIP' = '/membership/update',
  'MEMBERSHIP_CHANGE_STATUS' = '/membership/change-status',
  'GET_MEMBERSHIP_VALIDITY' = '/membership/validities',

  //* Subscription
  'GET_ALL_SUBSCRIPTION' = '/subscription/all',
  'CREATE_SUBSCRIPTION' = '/subscription/create',

  //*Report User
  'GET_REPORT_USER_REGISTER' = '/report/user-registration',
  'GET_REPORT_USER_SUBSCRIPTION' = '/report/user-subscription',
  'GET_REPORT_MOST_VIEW_PLAYLIST' = '/report/most-view-playlist',
  'GET_REPORT_PLAYLIST_PUBLISHED' = '/report/playlist-published',

  //* Configuration
  'GET_ALL_CONFIGURATION' = '/configuration/all',
  'GET_ONE_CONFIGURATION' = '/configuration/one',
  'UPDATE_CONFIGURATION' = '/configuration/update',
  'CREATE_CONFIGURATION' = '/configuration/init-configuration',

  //*SlideShow
  'GET_ALL_SLIDER' = '/slider/all',
  'GET_ONE_SLIDER' = '/slider/one',
  'UPDATE_SLIDER' = '/slider/update',
  'CREATE_SLIDER' = '/slider/create',
  'DELATE_SLIDER' = '/slider/delete',

  // Mobile
  USER_SIGNIN_WITH_PHONE = '/users/sign-in-with-phone',
  USER_SIGNIN_WITH_USERNAME_PASSWORD = '/users/sign-in',

  //*content
  GET_PLAYLIST = '/playlist/all',
  GET_ONE_PLAYLIST = '/playlist/one/',
  GET_SORT_CONTENT = '/playlist/sort-contents',
  SEARCH_CONTENT = '/playlist/all',
  PLAYLIST_RANDOMLY = '/playlist/random-playlist',

  //*content category
  GET_CONTENT_CATEGORY = '/playlist-category/all',
  GET_ONE_CONTENT_CATEGORY = '/playlist-category/one/',

  //* slide show
  GET_ALL_SLIDE_SHOW = '/slider/all',

  // * history
  VIEW_HISTORY = '/view-history/all',

  //*favorite
  GET_ALL_FAVORITE = '/favorite/all',
  CREATE_FAVORITE = '/favorite/create',
}
