import {
  ParamOption,
  useSingleSuraFetcherFactory,
  useSuraFetcherFactory,
} from '@asurraa/sura-ui-fetcher';

import { AxiosHttp } from '../config/axios.config';
import { URI_PATH } from '../constants/uri-path.constant';
import { GeneratorApi } from '../types/base-dto.interface';

export type TMembership = GeneratorApi['CreateMembershipDto'];
export type TMembershipResponse = GeneratorApi['MembershipResponseDto'];
export type TMembershipFieldList =
  GeneratorApi['CreateMembershipValidityDto'] & { id?: string | number };
export type TValidityResponse = GeneratorApi['MembershipValidityResponseDto'];
export type TChangeActivationStatus = GeneratorApi['IdDto'];
export type TResponseSingleMembership =
  GeneratorApi['SingleMembershipValidityDto'];

export const useMembershipService = () => {
  return useSuraFetcherFactory<TMembership, TMembershipResponse>({
    GET_ALL: URI_PATH.GET_ALL_MEMBERSHIP,
    CREATE: URI_PATH.CREATE_MEMBERSHIP,
    UPDATE: URI_PATH.UPDATE_MEMBERSHIP,
    GET_ONE: URI_PATH.GET_ONE_MEMBERSHIP,
    DELETE: URI_PATH.DELETE_MEMBERSHIP,
  });
};

export const updateUserActivationService = (data: TChangeActivationStatus) => {
  return AxiosHttp.post(URI_PATH.MEMBERSHIP_CHANGE_STATUS, data);
};

export const useGetMembershipValidity = (props: ParamOption) => {
  return useSingleSuraFetcherFactory<TResponseSingleMembership[]>({
    urlRoute: URI_PATH.GET_REPORT_USER_REGISTER,
    paramOption: props,
  });
};

export type TResponseCurrentMemberShip = GeneratorApi['MembershipDto'];

export const useCurrentMembershipService = (paramOpt: ParamOption) => {
  return useSingleSuraFetcherFactory<TResponseCurrentMemberShip>({
    urlRoute: URI_PATH.GET_CURRENT_MEMBERSHIP,
    paramOption: paramOpt,
  });
};
