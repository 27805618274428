import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import AppProviderWrapper from './app/AppProviderWrapper';

import './styles.css';
import './styles/index.less';

import * as Sentry from '@sentry/browser';

Sentry.init({
  dsn: 'https://ca6b9f678eed4013999831dd727ffacd@glitchtip.monitoring.asurraa.dev/22',
  environment: process.env['NX_APP_MODE'],
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <StrictMode>
    <AppProviderWrapper />
  </StrictMode>
);
