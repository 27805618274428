import { useSuraFetcherFactory } from '@asurraa/sura-ui-fetcher';
import { AxiosHttp } from '../config/axios.config';
import { URI_PATH } from '../constants/uri-path.constant';
import { GeneratorApi } from '../types/base-dto.interface';

export type TConfiguration = GeneratorApi['ConfigurationResponseDto'];

export const useConfigurationService = () => {
  return useSuraFetcherFactory<TConfiguration, TConfiguration>({
    CREATE: URI_PATH.CREATE_CONFIGURATION,
    GET_ALL: URI_PATH.GET_ALL_CONFIGURATION,
    UPDATE: URI_PATH.UPDATE_CONFIGURATION,
  });
};

export const getAllConfigurationService = () => {
  return AxiosHttp.get<TConfiguration[]>(URI_PATH.GET_ALL_CONFIGURATION);
};
