import {
  BoxPlotOutlined,
  DashboardOutlined,
  PlayCircleOutlined,
  SearchOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { MenuDataItem, Route } from '@ant-design/pro-layout/lib/typings';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { ROUTE_PATH } from '../constants/path.route';

export type chlatSubRoute = MenuDataItem & {
  description?: string;
};
export interface LayoutRoute extends Route {
  routes: chlatSubRoute[];
}

export const useGetSiderLayout = () => {
  const { t } = useTranslation();

  const sideMenu: LayoutRoute = {
    path: '/',
    routes: [
      // {
      //   name: t('Home'),
      //   exact: true,
      //   icon: <DashboardOutlined />,
      //   key: ROUTE_PATH.HOME,
      //   path: ROUTE_PATH.HOME,
      // },
      // {
      //   name: t('Category'),
      //   exact: true,
      //   icon: <BoxPlotOutlined />,
      //   key: ROUTE_PATH.CATEGORY,
      //   path: ROUTE_PATH.CATEGORY,
      // },
      {
        name: t('Smart Library'),
        exact: true,
        icon: <BoxPlotOutlined />,
        key: ROUTE_PATH.SMART_LIBRARY,
        path: ROUTE_PATH.SMART_LIBRARY,
      },

      // {
      //   name: t('Play'),
      //   exact: true,
      //   icon: <PlayCircleOutlined />,
      //   key: ROUTE_PATH.PLAY,
      //   path: ROUTE_PATH.PLAY,
      // },
      // {
      //   name: t('Library'),
      //   exact: true,
      //   icon: <PlayCircleOutlined />,
      //   key: ROUTE_PATH.LIBRARY,
      //   path: ROUTE_PATH.LIBRARY,
      // },
      {
        name: t('Setting'),
        exact: true,
        icon: <SettingOutlined />,
        key: ROUTE_PATH.SETTING,
        path: ROUTE_PATH.SETTING,
      },
    ],
  };
  const sideMenuMobile = sideMenu['routes']
    ?.filter((item) => item?.children === undefined)
    ?.filter((item) => item?.hideInMenu === false)
    ?.map((item) => {
      return {
        name: item['name'],
        icon: item['icon'],
        path: item['path'],
        description: item['description'] ?? '',
      };
    });

  return { sideMenu, sideMenuMobile };
};
