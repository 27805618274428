import {
  ParamOption,
  useSingleSuraFetcherFactory,
} from '@asurraa/sura-ui-fetcher';
import { GeneratorApi } from '@chlat/share-interface';
import { URI_PATH } from '../constants/uri-path.constant';

export type ResponsePlaylist = GeneratorApi['PlaylistResponseDto'];
export type SearchContentResponse = ResponsePlaylist;

export const useGetSearchContent = (paramOpt: ParamOption) => {
  return useSingleSuraFetcherFactory<SearchContentResponse[]>({
    urlRoute: URI_PATH.SEARCH_CONTENT,
    paramOption: paramOpt,
  });
};
