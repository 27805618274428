import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Input, message, Radio, Typography } from 'antd';
import { useEffect, useState } from 'react';
import Img from 'react-cool-img';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/main-logo.png';
import { Center } from '../../components/style-component/layout.style-component';
import { ROUTE_PATH } from '../../constants/path.route';
import { useAuthentication } from '../../hooks/useAuth';
import i18n, { setLangToLocalStorage } from '../../locales/i18n.main';
import { userLoginWithUsernameAndPassword } from '../../services/auth.service';

const LoginPage = () => {
  const { t } = useTranslation();

  const { handleSubmit, control } = useForm();
  const navigate = useNavigate();
  const [randomNumber, setRandomNumber] = useState<number>(1);
  const [loading, setLoading] = useState(false);

  const { saveUser } = useAuthentication();

  const onSubmit = (value) => {
    setLoading(true);
    userLoginWithUsernameAndPassword(value)
      .then((res) => {
        setLoading(false);
        const { token } = res.data;
        saveUser({ token: token });
        navigate(ROUTE_PATH.SMART_LIBRARY);
      })
      .catch((err) => {
        message.error(`${err.message} | :(`);
        setLoading(false);
      });
  };

  const randomImageUrl = `https://source.unsplash.com/random/1920x1080/?asian-kid`;
  const RandomPictureLayout = () => (
    <div
      style={{
        width: '100%',
        height: '100%',
        position: 'fixed',
        left: 0,
        right: 0,
        zIndex: -1,
      }}
    >
      <Img style={{ width: '100%', height: '100%' }} src={randomImageUrl} />
    </div>
  );

  const LoginLayout = (
    <div className="flex h-screen ">
      <div style={{ backgroundColor: '#EEEEEE' }} className="w-full">
        <Center className="">
          <form>
            <div
              style={{ backgroundColor: '#EEEEEE' }}
              className="rounded-xl px-12 py-5"
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <div style={{ padding: 20 }}>
                  <img src={logo} width={120} alt={'logo'} />
                </div>
              </div>

              <Controller
                name="username"
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <Input
                      placeholder={t('Username')}
                      name={t('Username')}
                      prefix={<UserOutlined />}
                      autoComplete="off"
                      value={value}
                      onChange={onChange}
                    />
                  );
                }}
              />

              <Controller
                name="password"
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <Input.Password
                      placeholder={t('Password')}
                      style={{ marginTop: 10 }}
                      name={t('Password')}
                      prefix={<LockOutlined />}
                      autoComplete="off"
                      value={value}
                      onChange={onChange}
                    />
                  );
                }}
              />

              <div className="flex justify-center pt-5 ">
                <div style={{ width: '50%', justifyContent: 'center' }}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    block
                    className="login-form-button "
                    loading={loading}
                    onClick={handleSubmit(onSubmit)}
                  >
                    {t('Login')}
                  </Button>
                </div>
              </div>

              <br />
              <div className="flex justify-center">
                <Radio.Group
                  onChange={(e) => {
                    const lang = e.target.value;
                    setLangToLocalStorage(lang);
                    i18n.changeLanguage(lang);
                  }}
                  defaultValue={'km'}
                >
                  <Radio value={'km'}>🇰🇭 Khmer</Radio>
                  <Radio value={'en'}>🇺🇸 English</Radio>
                </Radio.Group>
              </div>

              <br />
              <div className="flex justify-center">
                <div
                  style={{
                    bottom: 20,
                  }}
                >
                  <Typography.Text>
                    {t('Powered by')}{' '}
                    <b
                      className="cursor-pointer"
                      onClick={() => {
                        window.open('https://asurraa.com', '_blank');
                      }}
                    >
                      AsurRaa
                    </b>
                  </Typography.Text>
                </div>
              </div>
            </div>
          </form>
        </Center>
      </div>
    </div>
  );

  return (
    <div style={{ position: 'relative' }}>
      {LoginLayout}
      <div id={'recaptcha-container'}></div>
    </div>
  );
};

export default LoginPage;
