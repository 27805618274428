import { LoginOutlined, SearchOutlined, UserOutlined } from '@ant-design/icons';
import ProLayout from '@ant-design/pro-layout';
import { Route as LayoutRoute } from '@ant-design/pro-layout/lib/typings';
import { AsurRaaBaseImageViewer } from '@asurraa/sura-ui-image-viewer/dist/AsurRaaBaseImageViewer';
import { MenuItemsProps } from '@asurraa/sura-ui-pico-krubkrong-layout';
import type { SpotlightAction } from '@mantine/spotlight';
import { openSpotlight, SpotlightProvider } from '@mantine/spotlight';
import { IconSearch } from '@tabler/icons';
import { Avatar, Button, Dropdown, Menu, message } from 'antd';
import React, { Fragment, ReactNode, Suspense, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoLanguageOutline } from 'react-icons/io5';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { uid } from 'uid';
import chlat from '../assets/main-logo.png';
import { AppLoading } from '../components/app-loading/app-loading';
import { LoadingAnimate } from '../components/loading-animate/global-loading.animate.page';
import { getImageFullPath } from '../constants/app.constant';
import { ROUTE_PATH } from '../constants/path.route';
import { useAuthentication } from '../hooks/useAuth';
import { langKeyArray, setLangToLocalStorage } from '../locales/i18n.main';
import { useGetSiderLayout } from '../router/menu.list';
import { useGetAdminServices } from '../services/admin.service';
import { useGetSearchContent } from '../services/search.service';
import { useStoreDeveloperConfig } from '../zustands/config.zustand';
import { useGlobalLoading } from '../zustands/global-loading.zustand';

const AsurRaaProMasterLayout: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const route = useNavigate();
  const { globalLoading } = useGlobalLoading();
  const { t, i18n } = useTranslation();
  const {
    stateData: { lang },
  } = useStoreDeveloperConfig();
  const location = useLocation();
  const { logoutUser } = useAuthentication();
  const { sideMenu } = useGetSiderLayout();
  const { data: adminData } = useGetAdminServices();

  const [searchChange, setSearchChange] = useState('');
  const { data: searchData } = useGetSearchContent({
    param: `?sort%5Btitle%5D=ASC&search%5Btitle%5D=${searchChange}`,
    limit: 5,
  });

  const newSideMenu: LayoutRoute = {
    ...sideMenu,
    routes: sideMenu?.routes?.map((data: any, k) => {
      return { ...data, name: data?.name };
    }),
  };

  const navigationMenuComponent = () => {
    const navigationMenu: MenuItemsProps[] = [
      {
        label: t('Logout'),
        key: uid(),
        icon: <LoginOutlined />,
        onClick: () => {
          logoutUser();
        },
      },
    ];
    return <Menu items={navigationMenu} />;
  };
  const languageMenuComponent = () => {
    const menuItemsLang: MenuItemsProps[] = langKeyArray(lang)?.map(
      (lang, index) => {
        return {
          label: t(lang.message),
          key: index,
          onClick: () => {
            i18n.changeLanguage(lang.key);
            message.info(`Change to ${t(lang.message)}`);
            setLangToLocalStorage(lang.key);
          },
        };
      }
    );
    return <Menu items={menuItemsLang} />;
  };

  const actions: SpotlightAction[] =
    searchData?.map((res) => {
      return {
        title: res.title,
        description: `view ${res.views}`,
        icon: (
          <AsurRaaBaseImageViewer
            antdImageProps={{ width: 100, height: 100 / 2 }}
            isPreview={true}
            value={res.thumbnail}
          />
        ),
        onTrigger: () => {
          route(ROUTE_PATH.VIDEO + '/' + res.id);
        },
      };
    }) ?? [];

  return (
    <div style={{ height: '100vh' }}>
      {globalLoading === true ? (
        <div className="flex min-h-screen items-center justify-center align-middle">
          <div>
            <LoadingAnimate />
          </div>
        </div>
      ) : (
        <Fragment>
          <SpotlightProvider
            actions={actions}
            searchIcon={<IconSearch size={18} />}
            searchPlaceholder="Search..."
            shortcut="ctrl + k"
            nothingFoundMessage="Can't find your videos..."
            onQueryChange={(query) => {
              setSearchChange(query);
            }}
          >
            <ProLayout
              disableContentMargin
              fixedHeader
              selectedKeys={[location.pathname]}
              defaultSelectedKeys={[ROUTE_PATH.SMART_LIBRARY]}
              fixSiderbar
              title=""
              navTheme={'light'}
              layout="top"
              contentWidth="Fluid"
              logo={
                <img
                  onClick={() => {
                    route(ROUTE_PATH.SMART_LIBRARY);
                  }}
                  src={chlat}
                  alt="rean-aan"
                  width={40}
                />
              }
              siderWidth={200}
              className="umi-plugin-layout-main"
              rightContentRender={() => (
                <Fragment>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Button
                      onClick={() => openSpotlight()}
                      size="small"
                      type="primary"
                      style={{ marginRight: 10 }}
                    >
                      <SearchOutlined />
                    </Button>
                    <Dropdown
                      className="cursor-pointer"
                      overlay={languageMenuComponent()}
                      arrow
                    >
                      <div className="pt-1 pr-2">
                        <IoLanguageOutline />
                      </div>
                    </Dropdown>
                    <Dropdown
                      className="cursor-pointer"
                      overlay={navigationMenuComponent()}
                      arrow
                    >
                      <div className="mr-0 flex flex-row pb-0">
                        <div className="pr-2">
                          {adminData?.avatar ? (
                            <Avatar
                              src={getImageFullPath(adminData?.avatar)}
                              crossOrigin={'anonymous'}
                            />
                          ) : (
                            <Avatar size="large" icon={<UserOutlined />} />
                          )}
                        </div>
                        <div className="pr-2 font-mono">
                          {adminData?.firstname + ' ' + adminData?.lastname}
                        </div>
                      </div>
                    </Dropdown>
                  </div>
                </Fragment>
              )}
              menuItemRender={(item, dom) => (
                <Link to={item?.path ?? '/'}>{dom}</Link>
              )}
              route={newSideMenu}
            >
              <div
                style={{
                  paddingLeft: 20,
                  paddingRight: 20,
                  paddingTop: 10,
                }}
              >
                <Suspense
                  fallback={
                    <div>
                      <AppLoading />
                    </div>
                  }
                >
                  {children}
                </Suspense>
              </div>
            </ProLayout>
          </SpotlightProvider>
        </Fragment>
      )}
    </div>
  );
};

export default AsurRaaProMasterLayout;
